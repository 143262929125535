import React from "react";

const CnsCarousel = () => (
    <div id="myCarousel" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" className="active"/>
            <li data-target="#myCarousel" data-slide-to="1" className=""/>
            <li data-target="#myCarousel" data-slide-to="2" className=""/>
        </ol>
        <div className="carousel-inner">
            <div className="carousel-item active" style={{backgroundImage: `url(${require("../assets/img/show-5.jpg")})`}}>

                <div className="container">
                    <div className="carousel-caption text-left">

                    </div>
                </div>
            </div>
            <div className="carousel-item" style={{backgroundImage: `url(${require("../assets/img/show-7.jpg")})`}}>

                <div className="container">
                    <div className="carousel-caption">

                    </div>
                </div>
            </div>
            <div className="carousel-item"
                 style={{backgroundImage: `url(${require("../assets/img/show-6.jpg")})`}}>

                <div className="container">
                    <div className="carousel-caption text-right">
                    </div>
                </div>
            </div>
        </div>
        <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"/>
            <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"/>
            <span className="sr-only">Next</span>
        </a>
    </div>
)

export default CnsCarousel;