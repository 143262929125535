import React from "react";

const ErrorLoader = () =>
    <div className="alert alert-primary" role="alert">
        Ein Netzwerkfehler ist aufgetreten, die Inhalte konnte
        nicht geladen werden. Bitte prüfen Sie Ihre
        Internetverbindung
        <button type="button" className="btn btn-primary"
                style={{marginLeft: 10}}
                onClick={() => window.location.reload(false)}>
            Inhalte jetzt laden
        </button>
    </div>

export default ErrorLoader