import React from "react"
import { Helmet } from "react-helmet-async"


const SuccessPage = () => {
    return (
        <>
            <Helmet>
                <title>Vielen Dank für die Abgabe - Clean & Safe Siegel -
                    Selbstinspektion · DS
                    Destination Solutions
                </title>
            </Helmet>
            <div className="container contentblock">
                <div className="row">
                    <div className="col-12">
                        <div
                            className="bd-placeholder-img rounded-circle mx-auto siegel-self-on">✓
                        </div>
                        <br/>
                        <br/>
                        <h1>
                            Vielen Dank für die Abgabe einer Clean & Safe
                            Selbstauskunft für Ihre Unterkunft
                            "Testunterkunft"
                        </h1>
                        <br/>
                        <br/>
                        <img alt="selfinspectionLogo"
                             src={require("../assets/img/content-image-9.jpg")}
                             className="float-left"
                             style={{marginRight: 16}} width="100"/>
                        <span className="font-weight-bold">
                            Wir haben Ihre Angaben geprüft und der Eintrag Ihrer Unterkunft wird auf den HRS Group
                            Portalen HRS Holidays und Tiscover.com sowie auf teilnehmenden Partnerseiten nun um das
                            Clean & Safe Self Inspected Siegel ergänzt.</span>
                        <br/><br/>
                        Bitte beachten Sie diese Selbstauskunft zu aktualisieren
                        oder zu widerrufen,
                        falls die bereitgestellten Informationen nicht mehr auf
                        Ihre
                        Unterkunft
                        zutreffen sollten.
                        <br/><br/>
                        Bei Rückfragen stehen wir gerne per Email zur Verfügung.
                        <br/><br/>
                        Ihr DS Destination Solutions Team<br/>
                        <a href="mailto:Clean-and-safe@ds-destinationsolutions.com">Clean-and-safe@ds-destinationsolutions.com</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessPage