import React from "react"

const CnsOverview = () =>
    <>
        <div className="container contentblock">
            <hr className="featurette-divider"/>
            <div className="row">
                <div className="col-sm-12">
                    <h2 className="text-spacer">Welche Möglichkeiten habe ich als Gastgeber?</h2>
                    <div className="card">
                        <div className="card-body text-center">
                            <p className="card-text font-weight-bold">
                                <img alt="Siegel Übersicht"
                                     src={require("../assets/img/content-image-4.png")}
                                     className="img-fluid text-center"/>
                                <br/>
                                <br/>
                                Unterkünfte, die mit einem Clean & Safe Label
                                ausgezeichnet
                                und auf den HRS Group Portalen entsprechend gekennzeichnet
                                sind,
                                wurden auf die Erfüllung der obligatorischen Kriterien des
                                Clean & Safe Siegels hin überprüft:.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container contentblock">

            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-body text-center">
                            <h5 className="card-title">Die kostenlose Option</h5>
                            <p className="card-text">
                                <img alt="Siegel Self Inspected"
                                     src={require("../assets/img/content-image-3.jpg")}
                                     className="img-fluid text-center"/>
                                <br/>
                                Für Unterkünfte, die eine<span className="font-weight-bold">
                                                    Selbstauskunft
                                                    bereitstellen</span>, die detailliert darlegt, dass sämtliche im
                                Clean & Safe Siegel spezifizierten Pflicht-Maßnahmen
                                ordnungsgemäß
                                im Betrieb umgesetzt und überwacht werden.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="card">
                        <div className="card-body text-center">
                            <h5 className="card-title">Die Experten-Option</h5>
                            <p className="card-text">
                                <img alt="Siegel Expert"
                                     src={require("../assets/img/content-image-5.jpg")}
                                     className="img-fluid text-center"/>
                                <br/>
                                Für Unterkünfte, die sich im Rahmen einer Videotelefonie-Inspektion
                                von
                                unserem
                                <span className="font-weight-bold">unabhängigen externen Experten</span>
                                (SGS) inspizieren und bewerten lassen. SGS prüft die im Clean &
                                Safe
                                Siegel spezifizierten Pflicht-Maßnahmen auf korrekte und nachhaltige
                                Implementierung. Diese Version des Siegels vermittelt Ihren Gästen
                                zusätzliche Sicherheit und Ihnen weitere Vorteile.
                            </p>
                            <a href="/#" className="btn btn-primary">mehr erfahren</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

export default CnsOverview