import React, {useState} from "react"
import {useHistory, Redirect, useLocation} from "react-router-dom"
import Api from "../api/CleanAndSafeApi"
import MeasureCheckbox from "./MeasureCheckbox"
import SurveyFormLoader from "./SurveyFormLoader"
import {useAsync} from "../Helpers"

const SurveyForm = () => {
    const location = useLocation()
    const history = useHistory()
    if (location.pathname.split('/')
        .filter(value => value !== '').length < 2) {
        history.push("/error")
    }
    Api.setObjectPath(location.pathname)

    const forceUpdate = useState(0)[1]
    const [consentStatement, changeStatementOfConsent] = useState(false)

    const catalogs = useAsync(Api.getCatalogs)
    let inspections = useAsync(Api.getInspections)

    const renderChecklist = () => {
        let items = []
        for (const topic of Object.values(catalogs.value[inspections.value.getClassification()])) {
            if (inspections.value.isTopicActive(topic.id)) {
                items.push(<li key={topic.id}
                               className="list-group-item list-group-item-secondary">
                    <span className="font-weight-bold">{topic.name}</span>
                </li>)
                for (const measure of Object.values(topic.measures)) {
                    items.push(<li key={topic.id + measure.id}
                                   className="list-group-item">
                        <MeasureCheckbox measure={measure}
                                         inspections={inspections.value}
                                         checked={inspections.value.isMeasureActive(measure.id)}
                                         onChange={e => handleChangeMeasureCheckbox(e)}/>
                    </li>)
                }
            }
        }
        return items
    }

    const handleChangeMeasureCheckbox = (e) => {
        e.target.checked ?
            inspections.value.activateMeasure(e.target.value) :
            inspections.value.deactivateMeasure(e.target.value)
        forceUpdate(s => !s)
    }

    const isInputValid = () => {
        let valid = consentStatement
        const activeMeasures = inspections.value.getActiveMeasures()
        for (const topic of Object.values(catalogs.value[inspections.value.getClassification()])) {
            if (!valid) break
            if (inspections.value.isTopicActive(topic.id))
                for (const measure of Object.values(topic.measures)) {
                    if (!valid) break
                    measure.mandatory &&
                    !activeMeasures.includes(measure.id) &&
                    (valid = false)
                }
        }
        return valid
    }

    const changeClassification = e => {
        inspections.value.setClassification(e.target.value)
        forceUpdate(s => !s)
    }

    const submitInspections = async () => {
        let validInspections = {
            selfInspection: {
                classification: inspections.value.getClassification(),
                activeTopics: inspections.value.getActiveTopics(),
                activeMeasures: inspections.value.getActiveMeasures()
            }
        }
        try {
            await Api.patchInspections(validInspections)
        } catch (e) {
            window.confirm('fehler')
        }
        history.push("/success")
    }

    if (catalogs.value && inspections.value) {
        for (const value of Object.values(catalogs.value[inspections.value.getClassification()])) {
            value.mandatory && inspections.value.activateTopic(value.id)
        }
    }

    return inspections.loading || catalogs.loading ?
        <SurveyFormLoader/> :
        catalogs.error ?
            <SurveyFormLoader error={true}/> :
            inspections.error ?
                <Redirect to="/error"/> :
                <div className="container contentblock">
                    <hr className="featurette-divider"/>
                    <div className="row">
                        <div className="col-12">
                            <h1>Ihr Weg zum Clean & Safe Self Inspected Siegel
                                mittels Selbstinspektion</h1>
                            <span className="badge badge-dark">1.</span>
                            <span className="font-weight-bold">
                            Clean & Safe Maßnahmenplan prüfen und den eigenen
                            Maßnahmen abgleichen</span>
                            <br/>
                            <br/>
                            Nachfolgend finden Sie die im Rahmen des Clean &
                            Safe
                            Siegels empfohlenen Hygiene- und Reinigungsmaßnahmen
                            für Ferienunterkünfte. Für den Erhalt des Siegels
                            verpflichtende Punkte sind mit * markiert.{' '}
                            <span className="font-weight-bold">
                            Bitte bestätigen Sie mittels Anhaken der einzelnen
                            Maßnahmen, dass diese in Ihrem Unterkunftsbetrieb
                            verlässlich umgesetzt und kontinuierlich geprüft werden.
                        </span>
                            <br/>
                            <br/>
                            Die in der Checkliste empfohlenen Maßnahmen
                            variieren je nach Unterkunftsbetrieb geringfügig um
                            bestmöglich auf die unterschiedlichen Gegebenheiten
                            und Anforderungen eingehen zu können.
                            <br/>
                            <br/>
                            <span className="font-weight-bold">
                            Bitte wählen Sie aus um welchen Unterkunftstyp es
                            sich bei der Unterkunft handelt, für die Sie eine
                            Selbstauskunft einreichen möchten:</span>
                            <br/>
                            <div>
                                <label className="container-radio"
                                       htmlFor="exampleRadios22">
                                    Parahotellerie Betrieb (z.B. Ferienhaus,
                                    Ferienwohnung, Bauernhof, Appartement etc.)
                                    <input className="checkbox"
                                           type="radio"
                                           name="exampleRadiosopt"
                                           id="exampleRadios22"
                                           value="other"
                                           checked={
                                               inspections.value.getClassification() === 'other'
                                           }
                                           onChange={e => changeClassification(e)}/>
                                    <span
                                        className="checkmark-radio"/>
                                </label>
                            </div>
                            <div>
                                <label className="container-radio"
                                       htmlFor="exampleRadios11">
                                    Hotel oder hotelähnlicher Betrieb
                                    (z.B: Pension)
                                    <input className="checkbox"
                                           type="radio"
                                           name="exampleRadiosopt"
                                           id="exampleRadios11"
                                           value="hotel"
                                           checked={
                                               inspections.value.getClassification() === 'hotel'
                                           }
                                           onChange={e => changeClassification(e)}/>
                                    <span
                                        className="checkmark-radio"/>
                                </label>
                            </div>
                            <br/>
                            <span className="font-weight-bold">
                            Bitte wählen Sie welche der folgenden optionalen
                            Leistungen für die zu prüfende Unterkunft während
                            der Covid-19 Pandemie angeboten werden:</span>
                            <br/>
                            <div
                                className="custom-control custom-checkbox check-margin">
                                <input type="checkbox"
                                       className="custom-control-input"
                                       id="RestaurantCheck"
                                       value="catering"
                                       checked={inspections.value.isTopicActive('catering')}
                                       onChange={(e) => {
                                           e.target.checked ?
                                               inspections.value.activateTopic(e.target.value) :
                                               inspections.value.deactivateTopic(e.target.value)
                                           forceUpdate(s => !s)
                                       }}/>
                                <label className="custom-control-label label2"
                                       htmlFor="RestaurantCheck">
                                    Frühstück- oder Restaurantleistungen</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                       className="custom-control-input"
                                       id="SpaCheck"
                                       onChange={() => {
                                       }}
                                       checked=""/>
                                <label className="custom-control-label label2"
                                       htmlFor="SpaCheck">
                                    Pool oder Spa
                                </label>
                            </div>
                            <br/>
                            <span className="font-weight-bold">Hinweis:</span>
                            {' '}Falls einzelne Maßnahmen für Ihre Unterkunft
                            keine
                            Anwendung finden, sollten Sie die Maßnahme dennoch
                            bestätigen wenn es sich um eine Pflichtmaßnahme
                            handelt.
                            Dies ist wichtig um das Siegel zu erhalten. Sie
                            können
                            also z.B. den Punkt „Der Empfangsbereich und alle
                            weiteren Gemeinschaftsbereiche wurden so
                            umgestaltet,
                            dass die vorgegebenen Abstandsregeln eingehalten
                            werden
                            können (z.B. Schutzscheibe am Rezeptionstisch,
                            Markierung der Laufrichtung, Markierungen auf dem
                            Boden
                            etc.).“ bestätigen wenn die Unterkunft (z.B. im
                            Falle
                            eines freistehenden Ferienhauses) über keinerlei
                            Empfangsbereich oder Gemeinschaftsbereiche verfügt.
                            Bitte beachten Sie jedoch, dass Sie im Falle sich
                            später eventuell verändernder Umstände in der
                            Unterkunft (z.B. nachträgliche Ergänzung eines
                            Pools)
                            auch diese Selbstauskunft entsprechend anpassen
                            damit
                            Ihre Angaben jederzeit der Realität entsprechen.
                            <br/>
                            <br/>
                            <span className="font-weight-bold">
                            Maßnahmen Checkliste für Parahotellerie Betriebe
                            (Maßnahmen Checkliste für Hotels und hotelähnliche
                            Betriebe)</span>
                            <br/>
                            <br/>
                            <ul className="list-group">
                                {
                                    renderChecklist()
                                }
                            </ul>
                            <br/>
                            <span className="badge badge-dark">2.</span>
                            <span className="font-weight-bold">
                            kostenlose Selbstauskunft einreichen</span>
                            <div
                                className="custom-control custom-checkbox check-margin">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1333"
                                    onChange={() => changeStatementOfConsent(s => !s)}/>
                                <label
                                    className="custom-control-label label2"
                                    htmlFor="customCheck1333">
                                    Ich bestätige, dass ich dazu berechtigt bin
                                    die
                                    auf dieser Website eingegebenen
                                    Informationen
                                    für die vorliegende Unterkunft bereit zu
                                    stellen.
                                    Ich bestätige zudem, dass alle von mir
                                    angegebenen
                                    Informationen wahr, richtig und korrekt
                                    sind.
                                    Ich bin damit einverstanden, dass die
                                    bereitgestellten Informationen ggfs.
                                    veröffentlicht wird. Ich erkläre mich damit
                                    einverstanden, dass die bereitgestellten
                                    Informationen rechtlich gegen mich oder mein
                                    Unternehmen verwendet werden können, falls
                                    ich
                                    falsche Informationen gemacht habe. Ich
                                    verpflichte mich dazu diese Selbstauskunft
                                    zeitnah zu aktualisieren oder zu widerrufen,
                                    falls die bereitgestellten Informationen
                                    nicht
                                    mehr auf meine Unterkunft zutreffen. Ich
                                    stimme
                                    den{' '}
                                    <a href="/#">
                                        Allgemeinen Geschäftsbedingungen
                                    </a>
                                    {' '}zu.
                                    <br/>
                                    <br/>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        id="submitbutton"
                                        title="Alle verpflichtenden Punkte* sowie AGB müssen markiert sein"
                                        onClick={() => {
                                            submitInspections()
                                        }}
                                        disabled={!isInputValid()}
                                    >
                                        Selbstauskunft
                                        einreichen
                                    </button>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
}

export default SurveyForm