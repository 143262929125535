import Axios from "axios"
import Inspections from "../entities/Inspections";

const client = Axios.create({
    baseURL: 'https://api.clean-and-safe.dev.bookingsolution.com'
})

let objectPath = null

let inspections = null

const CleanAndSafeApi = {
    setObjectPath: path => {
        typeof path === 'string' && !objectPath && (objectPath = path)
    },

    getCatalogs: async () => {
        let response = await client.get('/catalogs')
        let hotelCatalog, otherCatalog
        for (const catalog of response.data)
            catalog.name === 'hotel' ?
                hotelCatalog = catalog['topics'] :
                otherCatalog = catalog['topics']
        return {
            other: otherCatalog,
            hotel: hotelCatalog,
        }
    },

    getInspections: async () => {
        if (!inspections && objectPath) {
            let response = await client.get(`/inspections${objectPath}`)
            inspections = new Inspections(response.data)
        } else {
            //todo: what happens on failure?
        }
        return inspections
    },

    patchInspections: async (inspections) => {
        let response = await client.patch(
            `/inspections${objectPath}`,
            inspections
        )
        return response.data
    },

    getObjectName: () => inspections && inspections.name ? inspections.name : '',
}

export default CleanAndSafeApi
