import React from "react"

const ExemplaryPreview = () =>
    <div className="container contentblock">
        <div className="row">
            <div className="col-12 text-spacer">
                Beispiel der Siegel-Darstellung auf HRS Group Portalen
            </div>
            <div className="col-sm-12 card-spacer">
                <div className="card">
                    <div className="card-body text-center">
                        <p className="card-text">
                            <img alt="Beispiel HRS Holidays"
                                 src={require("../assets/img/content-image-8.jpg")}
                                 className="img-fluid text-center"/>
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-body text-center">
                        <p className="card-text">
                            <img alt="Beispiel HRS Holidays"
                                 src={require("../assets/img/content-image-7.jpg")}
                                 className="img-fluid text-center"/>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>

export default ExemplaryPreview