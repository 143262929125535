import { useEffect, useState } from "react"

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

export const useAsync = getMethod => {
    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getResource = async () => {
            try {
                setLoading(true)
                const value = await getMethod()
                setValue(value)
            } catch (e) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }
        getResource()
    }, [getMethod])

    return {value, error, loading}
}