import React from "react"
import {Helmet} from "react-helmet-async"
import SelfInspectionInfo from "../components/SelfInspectionInfo"
import CnsOverview from "../components/CnsOverview"
import ExemplaryPreview from "../components/ExemplaryPreview"
import SurveyForm from "../components/SurveyForm"

const MainPage = () =>
    <>
        <Helmet>
            <title>
                Clean & Safe Siegel - Selbstinspektion · DS Destination Solutions
            </title>
        </Helmet>
        <SelfInspectionInfo/>
        <CnsOverview/>
        <ExemplaryPreview/>
        <SurveyForm/>
    </>

export default MainPage