import React from "react"

const dsLogo = require("../assets/img/ds-logo.svg")

const DsHeader = () =>
    <header>
        <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <img src={dsLogo} alt="DS Destination Solutions" className="logo"/>
        </nav>
    </header>

export default DsHeader