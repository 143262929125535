import React from "react";

const IdentificationErrorPage = () =>
    <div className="container contentblock">
        <div className="row">
            <div className="col-12">
                <div className="alert alert-danger" role="alert">
                    <img src={require("../assets/img/lb.svg")} alt="" width="" height="40"
                         className="float-left lbdanger"/>
                        <h5 className="alert-heading">
                            Fehler bei der
                            Identifizierung festgestellt, da der Link defekt
                            ist.
                        </h5>
                        Bitte nutzen Sie erneut den Link in der Booking Solution
                        unter Objektdaten / Merkmale.

                </div>
                Sollte der Fehler erneut auftreten, wenden Sie sich bitte
                an:
                <br/>
                <a href="mailto:Clean-and-safe@ds-destinationsolutions.com">Clean-and-safe@ds-destinationsolutions.com</a>
            </div>
        </div>
    </div>

export default IdentificationErrorPage