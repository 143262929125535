import React from "react"
import { scrollToTop } from "../Helpers"

const DsFooter = () =>
    <footer className="container">
        <p className="float-right toptolink" onClick={() => scrollToTop()}>
            nach oben
        </p>
        <p>© DS Destination Solutions 2020 ·{' '}
            <a href="https://www.ds-destinationsolutions.com/impressum.html"
               target="_blank"
               rel="noopener noreferrer">
                Impressum
            </a>
            {' '}·{' '}
            <a href="https://www.ds-destinationsolutions.com/datenschutz-sicherheit.html"
               target="_blank"
               rel="noopener noreferrer">
                Datenschutz & Sicherheit
            </a>
            {' '}·{' '}
            <a target="_blank"
               href="https://www.ds-destinationsolutions.com/kontakt.html"
               rel="noopener noreferrer">
                Kontakt
            </a>
        </p>
    </footer>

export default DsFooter;