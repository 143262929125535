import React from "react"
import {HelmetProvider} from "react-helmet-async"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import DsHeader from "./components/DsHeader"
import CnsCarousel from "./components/CnsCarousel"
import MainPage from "./views/MainPage"
import IdentificationErrorPage from "./views/IdentificationErrorPage"
import SuccessPage from "./views/SuccessPage"
import DsFooter from "./components/DsFooter"

const App = () =>
    <HelmetProvider>
        <Router>
            <DsHeader/>
            <main role="main">
                <CnsCarousel/>
                <Switch>
                    <Route path="/success">
                        <SuccessPage/>
                    </Route>
                    <Route path="/error">
                        <IdentificationErrorPage/>
                    </Route>
                    <Route path="/">
                        <MainPage/>
                    </Route>
                </Switch>
            </main>
            <DsFooter/>
        </Router>
    </HelmetProvider>

export default App