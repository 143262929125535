import React from "react"

const pictures = {
    soapDispenser: `url(${require("../assets/img/content-image-1.jpg")})`,
    notebook: `url(${require("../assets/img/content-image-10.jpg")})`,
    seal: `url(${require("../assets/img/content-image-3.jpg")})`,
}

const SelfInspectionInfo = () =>
    <>
        <div className="container contentblock">
            <div className="row">
                <div className="col-12">
                    <h1>Clean & Safe Siegel – Selbstinspektion</h1>
                    Guten Tag {/*Api.getObjectName()*/}!
                    <br/>
                    <br/>
                    Sie möchten Ihren Gästen trotz der aktuellen
                    COVID-19-Situation ein sicheres Gefühl geben damit sie den
                    Urlaub sorgenfrei genießen können? Kein Problem mit dem{' '}
                    <span className="font-weight-bold">
                        neuen Clean & Safe Siegel der HRS GROUP:
                    </span>
                    {' '}nutzen Sie die kostenlose Möglichkeit Ihre Unterkunft
                    als hygienisch geprüft und sicher hervorzuheben.
                    <br/>
                    <br/>
                    COVID-19 hat Hygienestandards weltweit in den Vordergrund
                    gerückt. Besonders auf detaillierte Informationen zu
                    Sicherheits- und Hygienemaßnahmen achten Urlauber derzeit
                    verstärkt bei der Buchung ihrer Unterkunft.
                    <br/>
                    <br/>
                    Die Gesundheit, Sicherheit und den Schutz von Gästen und
                    Partnern zu gewährleisten, steht für uns an erster Stelle.
                    Aus diesem Grund haben wir zusammen mit{' '}
                    <a href="https://www.sgsgroup.de/"
                       target="_blank"
                       rel="noopener noreferrer">
                        SGS, dem weltweit führenden Inspektions-,
                        Verifizierungs-, Prüf- und Zertifizierungsunternehmen
                    </a>
                    {' '}das „HRS GROUP Clean & Safe Siegel“ entwickelt. Wir
                    freuen uns, Sie mit diesem Siegel dabei zu unterstützen
                    (potentielle) Gäste einfach und effektiv über Ihre Hygiene-
                    und Reinigungsmaßnahmen zu informieren. Die Darstellung
                    Ihrer Unterkunft wird bei Sicherstellung bestimmter Covid-19
                    Maßnahmen um ein verlässliches Siegel ergänzt, das Ihrem
                    Betrieb zusätzliche Sichtbarkeit auf HRS Group Portalen und
                    Partnerseiten ermöglicht.
                </div>
            </div>
        </div>
        <div className="container marketing">
            <div className="row">
                <div className="col-lg-4">
                    <div className="bd-placeholder-img rounded-circle mx-auto"
                         style={{
                             backgroundImage: pictures.soapDispenser
                         }}/>
                    <h2>Schritt 1</h2>
                    <p>
                        Clean & Safe Maßnahmenplan prüfen und den eigenen
                        Maßnahmen abgleichen
                    </p>
                </div>
                <div className="col-lg-4">
                    <div className="bd-placeholder-img rounded-circle mx-auto"
                         style={{backgroundImage: pictures.notebook}}/>
                    <h2>Schritt 2</h2>
                    <p>
                        kostenlos Selbstauskunft <br/>abschicken
                    </p>
                </div>
                <div className="col-lg-4">
                    <div className="bd-placeholder-img rounded-circle mx-auto"
                         style={{backgroundImage: pictures.seal}}/>
                    <h2>Schritt 3</h2>
                    <p>
                        Ihre Unterkunft wird mit dem Clean & Safe Siegel
                        ausgezeichnet
                    </p>
                </div>
            </div>
        </div>
        <div className="container contentblock">
            <hr className="featurette-divider"/>
            <div className="row">
                <div className="col-sm-12">
                    <h2>Wofür steht das Clean & Safe Siegel?</h2>
                    <p>Auf diese 10 Punkte konzentriert sich die Inspektion:</p>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <span className="badge badge-dark">01</span>
                            <span className="font-weight-bold">
                                In der Unterkunft wird genügend Abstand gehalten
                            </span>
                            <p>
                                Einhalten von „Social Distancing“ in der
                                gesamten Unterkunft nach den gesetzlichen
                                Vorschriften.
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">02</span>
                            <span className="font-weight-bold">
                                Die Unterkunfts-Mitarbeiter sind speziell
                                geschult
                            </span>
                            <p>
                                Überarbeitete Trainingsprogramme für
                                Mitarbeiter, Lieferanten und Vertragspartner.
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">03</span>
                            <span className="font-weight-bold">
                                Kontaktflächen werden ausreichend gereinigt
                            </span>
                            <p>
                                Regelmäßige Reinigung von öffentlichen Bereichen
                                und oft genutzten Kontaktflächen, z.B. Türgriffe.
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">04</span>
                            <span className="font-weight-bold">
                                Die Unterkunft bietet Desinfektionsmittel &
                                Schutzmasken
                            </span>
                            <p>
                                Desinfektionsmittel und persönliche
                                Schutzausrüstung (z.B. Mund-Nasen-Masken) stehen
                                für Mitarbeiter und Gäste zur Verfügung.
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">05</span>
                            <span className="font-weight-bold">
                                Technisches Equipment wird gewartet
                            </span>
                            <p>
                                Regelmäßige Kontrolle von technischem Equipment,
                                z.B. Klimaanlage, Spül- und Waschmaschinen.
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">06</span>
                            <span className="font-weight-bold">
                                Hygienestandards für Essen & Getränke
                            </span>
                            <p>
                                Kontaktlose Gastronomie, verfügbare Take-Away
                                Optionen sowie desinfiziertes Besteck und
                                Geschirr – sofern die Unterkunft Frühstück oder
                                Restaurantleistungen bietet in allen
                                Gemeinschaftsbereichen, jedoch auch im Bezug auf
                                die Küchenausstattung der gebuchten Wohneinheit
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">07</span>
                            <span className="font-weight-bold">
                                Die Unterkunftsbetreiber agieren proaktiv
                            </span>
                            <p>
                                Kontinuierliche Risikobewertung und
                                Handlungsplanung seitens des Betreiber-Teams.
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">08</span>
                            <span className="font-weight-bold">
                                Kontrolle der Lieferanten
                            </span>
                            <p>
                                Zulieferer und Vertragspartner werden
                                kontrolliert, ob sie sich an die verschärften
                                geltenden Maßnahmen halten.
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">09</span>
                            <span className="font-weight-bold">
                                Rückverfolgungssystem, um Betroffene zu
                                informieren
                            </span>
                            <p>
                                System zur Rückverfolgung für eine schnelle
                                Reaktion bei Krankheitsfällen von Mitarbeitern,
                                Lieferanten oder
                            </p>
                        </li>
                        <li className="list-group-item">
                            <span className="badge badge-dark">10</span>
                            <span className="font-weight-bold">
                                Kontaktloser Empfang & Neuorganisation der
                                Abläufe
                            </span>
                            <p>
                                Lange Schlangen werden vermieden, kontaktloses
                                Ein- und Auschecken ist möglich.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>

export default SelfInspectionInfo