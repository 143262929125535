import "../assets/css/SurveyFormLoader.css"
import React from "react"
import ErrorLoader from "./ErrorLoader"

const SurveyFormLoader = props =>
    <div className="container contentblock">
        <hr className="featurette-divider"/>
        <div className="row">
            <div className="col-12">
                {props.error && <ErrorLoader/>}
                <h1>
                    <div className="animated-background heading"/>
                </h1>
                <div className="animated-background text"
                     style={{width: '35%'}}/>
                <div className="animated-background text"
                     style={{width: '20%'}}/>
                <p/>
                <ul className="list-group">
                    <li className="list-group-item list-group-item-secondary animated-background">
                        <div className="topic"/>
                    </li>
                    <li className="list-group-item">
                        <div
                            className="animated-background float-left keyNote"/>
                        <div className="animated-background float-left text"
                             style={{width: '20%'}}/>
                    </li>
                    <li className="list-group-item">
                        <div
                            className="animated-background float-left keyNote"/>
                        <div className="animated-background float-left text"
                             style={{width: '30%'}}/>
                    </li>
                    <li className="list-group-item">
                        <div
                            className="animated-background float-left keyNote"/>
                        <div className="animated-background float-left text"
                             style={{width: '40%'}}/>
                    </li>
                </ul>
            </div>
        </div>
    </div>

export default SurveyFormLoader