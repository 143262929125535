import React from "react"

const MeasureCheckbox = props => {
    const measure = props.measure

    return <div className="custom-control custom-checkbox check-margin">
        <input type="checkbox"
               className="custom-control-input"
               id={measure.id}
               value={measure.id}
               required={measure.mandatory}
               checked={props.checked}
               onChange={props.onChange/*e => {
                   e.target.checked ?
                       inspections.activateMeasure(e.target.value) :
                       inspections.deactivateMeasure(e.target.value)
                   setChecked(inspections.isMeasureActive(measure.id))
               }*/}/>
        <label className="custom-control-label label2"
               htmlFor={measure.id}>
            {measure.explanation}</label>
    </div>
}

export default MeasureCheckbox