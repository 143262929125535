class Inspections {
    constructor(inspections = null) {
        if (inspections.expertInspection)
            this.setInspectionsType('expertInspection')
        else if (inspections.selfInspection.validated) {
            inspections.selfInspection.classification === 'hotel' &&
            this.setClassification('hotel')
            this.setActiveTopics(inspections.selfInspection.activeTopics)
            this.setActiveMeasures(inspections.selfInspection.activeMeasures)
        }
    }

    /**
     *
     * @type {string}
     */
    inspectionsType = 'selfInspection'

    /**
     *
     * @type {string}
     */
    classification = 'other'
    /**
     *
     * @type {*[]}
     */
    activeTopics = []
    /**
     *
     * @type {*[]}
     */
    activeMeasures = []

    setInspectionsType = type => {
        this.inspectionsType = type
    }

    getInspectionsType = () => this.inspectionsType

    setClassification = value => {
        if (['other', 'hotel'].includes(value) &&
        this.classification !== value) {
            this.classification = value
            this.clearActiveTopics()
            this.clearActiveMeasures()
        }
    }

    getClassification = () => this.classification

    getActiveTopics = () => this.activeTopics

    setActiveTopics = topics => {
        Array.isArray(topics) && (this.activeTopics = topics)
    }

    activateTopic = topic => {
        typeof topic === 'string' &&
        !this.activeTopics.includes(topic) &&
        this.activeTopics.push(topic)
    }

    deactivateTopic = topic => {
        this.activeTopics = this.activeTopics.filter(e => e !== topic)
    }

    isTopicActive = topic => this.activeTopics.includes(topic)

    clearActiveTopics = () => { this.activeTopics.length = 0 }

    getActiveMeasures = () => this.activeMeasures

    setActiveMeasures = measures => {
        Array.isArray(measures) && (this.activeMeasures = measures)
    }

    activateMeasure = measure => {
        typeof measure === 'string' && this.activeMeasures.push(measure)
    }

    deactivateMeasure = measure => {
        this.activeMeasures = this.activeMeasures.filter(e => e !== measure)
    }

    isMeasureActive = measure => this.activeMeasures.includes(measure)

    clearActiveMeasures = () => { this.activeMeasures.length = 0 }
}

export default Inspections